<template>
    <div class="content_">
            <h1>企业级软件定制开发</h1>
            <h3>提供各类APP、小程序、公众号、网站、官网、管理系统、物联网系统等定制开发服务。</h3>
            <h3>通过软件定制开发，降低软件项目成本，快速实现商业目的。</h3>
    </div>
</template>

<script>

export default {
    name: 'service',
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style>

</style>